<template>
  <div
    class="plan"
    v-loading="loading"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-coin"
  >
    <div class="d-flex" style="flex-direction:row-reverse">
      <div class="switch-date">
        <div
          @click="planDate = 'Yearly'"
          :class="{
            'switch-button': true,
            'switch-active': planDate === 'Yearly'
          }"
        >
          Yearly
        </div>
        <div
          @click="planDate = 'Monthly'"
          :class="{
            'switch-button': true,
            'switch-active': planDate === 'Monthly'
          }"
        >
          Monthly
        </div>
      </div>
    </div>
    <div class="plan-cover">
      <div class="plan-item">
        <h2>Education Basic</h2>
        <h5>Best for limited needs</h5>
        <div class="plan-fee free-text">FREE</div>
        <!-- <div>
          <small></small>
        </div> -->
        <!-- <div >
        </div> -->
        <router-link
          class="plan-button free-button"
          style="text-decoration: none;display:block;color: white!important;width: 100%;"
          :to="{
            name: 'EducatorHome'
          }"
        >
          Free forever
        </router-link>
        <div class="mb-4 text-center price-text">
          <small>&nbsp;</small>
        </div>
        <div class="plan-options">
          <b>Key Features:</b>
          <div
            class="plan-option"
            v-for="(item, index) in freeList"
            :key="index"
          >
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <span v-show="index > 8">
                <b class="logo-color mr-1">AI</b>
              </span>
              <span v-html="item" />
            </span>
          </div>
        </div>
      </div>
      <div class="plan-item" v-show="false">
        <h2>Education Pro</h2>
        <h5>Best for access to single exam</h5>
        <div v-show="planDate === 'Yearly'">
          <div class="plan-fee money-text">
            $9.99
          </div>
          <small>*Billed yearly</small>
        </div>
        <div v-show="planDate === 'Monthly'">
          <div class="plan-fee money-text">
            $19.99
          </div>
          <small>*Billed monthly</small>
        </div>
        <div class="plan-button money-button">
          <!-- Get Started -->
          Coming soon
        </div>
        <div class="plan-options">
          <b>Everything in Basic plus:</b>
          <div
            class="plan-option"
            v-for="(item, index) in proList"
            :key="index"
          >
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span v-show="index > 8">
              <b class="logo-color mr-1">AI</b>
            </span>
            <span>
              {{ item }}
            </span>
          </div>
        </div>
      </div>
      <div class="plan-item plan-max">
        <h2>
          Education Max
          <span class="plan-tag">Popular</span>
        </h2>
        <h5>Best for access to multiple exams</h5>
        <div v-show="planDate === 'Yearly'">
          <div class="plan-fee money-text">
            $14.99
          </div>
        </div>
        <div v-show="planDate === 'Monthly'">
          <div class="plan-fee money-text">
            $29.99
          </div>
        </div>
        <div
          class="plan-button money-button plan-max"
          v-if="isLogin"
          @click="showMaxTypeDialog = true"
        >
          Get Started
        </div>
        <router-link
          v-else
          class="plan-button money-button plan-max"
          style="text-decoration: none;display:block;color: white!important;width: 100%;"
          :to="{
            name: 'EducatorHome'
          }"
        >
          Get Started
        </router-link>

        <div class="mb-4 text-center price-text">
          <small>billed per user per month</small>
        </div>
        <div class="plan-options">
          <b>Key Features:</b>
          <div
            class="plan-option"
            v-for="(item, index) in maxList"
            :key="index"
          >
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <span v-show="index > 8">
                <b class="logo-color mr-1">AI</b>
              </span>
              <span v-html="item" />
            </span>
          </div>
        </div>
      </div>
      <div class="plan-item plan-sales">
        <h2>Education Enterprise</h2>
        <h5 style="display: block;">
          Best for companies to white-label product with their own logo
        </h5>
        <div class="plan-fee free-text">&nbsp;</div>
        <router-link
          class="plan-button contact-button"
          style="text-decoration: none !important; color: white!important; display: block;"
          :to="{
            name: 'EducatorContact'
          }"
        >
          Contact sales
        </router-link>
        <div class="mb-4 text-center price-text">
          <small>&nbsp;</small>
        </div>
        <div class="plan-options">
          <b>Key Features:</b>
          <div
            class="plan-option"
            v-for="(item, index) in enterPriseList"
            :key="index"
          >
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <span v-show="index > 8 && index < 19">
                <b class="logo-color mr-1">AI</b>
              </span>
              <span v-html="item" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="showMaxTypeDialog"
      :width="isPhone ? '95%' : '80%'"
      class="p-0"
    >
      <h4 slot="title">
        <div class="plans-title plan-max-button ">
          Education Max Plan
        </div>
      </h4>
      <el-card v-if="!isMax">
        <Heading content="Max Plan" heading="h6"></Heading>
        <hr />
        <div>
          <div class="plan-options">
            <b>Key Features:</b>
            <div
              class="plan-option"
              v-for="(item, index) in maxList"
              :key="index"
            >
              <span class="icon">
                <i class="fa fa-check"></i>
              </span>
              <span>
                <span v-show="index > 8">
                  <b class="logo-color mr-1">AI</b>
                </span>
                <span v-html="item"></span>
              </span>
            </div>
          </div>
          <hr />
          <div class="text-right">
            <div class="d-flex" style="flex-direction:row-reverse">
              <div class="switch-date mb-0">
                <div
                  @click="planDate = 'Yearly'"
                  :class="{
                    'switch-button': true,
                    'switch-active': planDate === 'Yearly'
                  }"
                >
                  Yearly
                </div>
                <div
                  @click="planDate = 'Monthly'"
                  :class="{
                    'switch-button': true,
                    'switch-active': planDate === 'Monthly'
                  }"
                >
                  Monthly
                </div>
              </div>
            </div>
            <div v-show="planDate === 'Yearly'">
              <div class="d-flex mt-2" style="flex-direction:row-reverse">
                <b style="font-size: 1.5rem;">
                  <span class="max-color">$14.99</span>
                  * 12 =
                </b>
              </div>
              <div
                class="plan-fee money-text max-color"
                style="font-size: 3rem;"
              >
                $179.88
              </div>
            </div>
            <div v-show="planDate === 'Monthly'">
              <div
                class="plan-fee money-text max-color"
                style="font-size: 3rem;"
              >
                $29.99
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <el-button type="primary" @click="getMaxPayUrl">
            Pay
          </el-button>
        </div>
      </el-card>
      <el-card v-if="myPlans && myPlans.length > 0">
        <Heading content="My Plans" heading="h6"></Heading>
        <hr />
        <MyPlans :myPlans="myPlans"></MyPlans>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import plansApi from "@/apis/plans";
import moment from "moment";
import baseDomainPath from "@/common/baseDomainPath";
import userApi from "@/apis/user";
import MyPlans from "@/views/profile/components/MyPlans.vue";

export default {
  components: { MyPlans },

  mixins: [],

  props: [],
  data() {
    return {
      myPlans: [],
      planDate: "Yearly",
      freeList: [
        "Access to All Students' Features",
        "2 Full Length SATs",
        "2 Full Length ACTs",
        "2 Full Length APs (Coming Soon)",
        "1 Full Length PSAT (Coming Soon)",
        "2 Full Length TOEFLs (Coming Soon)",
        "2 Full Length IELTSs (Coming Soon)",
        "Create and Manage Classes",
        "Assign Tests and Homework",
        "Proctor Tests with Intelligent Cheating Intervention",
        "Create Unlimited Questions and Tests with AI",
        "Upload Custom Questions and Tests",
        "Smart Performance Reports",
        "Dynamic Progress Tracker",
        "Adaptive Assignment Creator",
        "Engagement Analytics",
        "Predictive Score Insights",
        "Smart Curriculum Builder",
        "Integrate With Your Curriculum"
      ],
      proList: [
        "Access to educator-created exams",
        "Access to All 50+ Full Length ACTs OR 13 SATs",
        "Access to topic-specific modules for selected test",
        "Unlimited drills/day for specified test"
      ],
      maxList: [
        "Access to All Students' Features",
        "<b>20</b> Full Length SATs",
        "<b>40</b> Full Length ACTs",
        "<b>20</b> Full Length APs (Coming Soon)",
        "<b>6</b> Full Length PSATs (Coming Soon)",
        "<b>50</b> Full Length TOEFLs (Coming Soon)",
        "<b>40</b> Full Length IELTSs (Coming Soon)",
        "Create and Manage Classes",
        "Assign Tests and Homework",
        "Proctor Tests with Intelligent Cheating Intervention",
        "Create Unlimited Questions and Tests with AI",
        "Upload Custom Questions and Tests",
        "Smart Performance Reports",
        "Dynamic Progress Tracker",
        "Adaptive Assignment Creator",
        "Engagement Analytics",
        "Predictive Score Insights",
        "Smart Curriculum Builder",
        "Integrate With Your Curriculum"
      ],
      enterPriseList: [
        "Access to All Students' Features",
        "<b>20</b> Full Length SATs",
        "<b>40</b> Full Length ACTs",
        "<b>20</b> Full Length APs (Coming Soon)",
        "<b>6</b> Full Length PSATs (Coming Soon)",
        "<b>50</b> Full Length TOEFLs (Coming Soon)",
        "<b>40</b> Full Length IELTSs (Coming Soon)",
        "Create and Manage Classes",
        "Assign Tests and Homework",
        "Proctor Tests with Intelligent Cheating Intervention",
        "Create Unlimited Questions and Tests with AI",
        "Upload Custom Questions and Tests",
        "Smart Performance Reports",
        "Dynamic Progress Tracker",
        "Adaptive Assignment Creator",
        "Engagement Analytics",
        "Predictive Score Insights",
        "Smart Curriculum Builder",
        "Integrate With Your Curriculum",
        "<b>Your Own Domain and Logo</b>",
        "<b>License to All of Our Tests</b>",
        "<b>Free Marketing Campaign</b>",
        "<b>Immediate Customer Support</b>",
        "<b>Priority to Feature Requests</b>"
      ],
      moment,
      // proTimeLimited: "",
      langIsEn: "",
      isTW: false,
      showMaxTypeDialog: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    ...mapState("user", ["token", "lang", "profile"]),
    isLogin() {
      // TODO: 應該還要檢查 token 是否合法？
      return this.token !== "";
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    userId() {
      return this.getProfile.id;
    },
    isMax() {
      let isTrue = false;
      this.myPlans.forEach(myPlan => {
        if (myPlan.subscription_plan.key_name === "max_education") {
          isTrue = true;
        }
      });
      return isTrue;
    }
  },
  watch: {
    userId(userId) {
      if (userId && this.isLogin) {
        this.getUserPlans(userId);
      }
    }
  },

  mounted() {
    if (this.userId && this.isLogin) {
      this.getUserPlans(this.userId);
    }
  },

  methods: {
    async getUserPlans(id) {
      const res = await userApi.getUserPlans(id, {
        status: "active"
      });
      this.myPlans = res;
    },
    getMaxPayUrl() {
      let proData = {
        subscription_plan_key_name: "max_education",
        type: "one_time",
        interval: null,
        interval_count: 1
      };
      let id = null;
      if (this.planDate === "Monthly") {
        proData.interval = "month";
      }
      if (this.planDate === "Yearly") {
        proData.interval = "year";
      }
      this.showMaxTypeDialog = false;
      this.getPayUrl(proData);
    },
    async getPayUrl(data) {
      if (data) {
        this.loading = true;
        const res = await plansApi.getPayUrl({
          ...data,
          success_url: `${baseDomainPath}educator/profile?active=plans`
        });
        if (res.url) {
          window.location.href = res.url;
        } else {
          this.loading = false;
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.plan {
  text-align: left;
  // color: var(--themeColor);
}
.switch-date {
  border: 1px solid #ccc;
  border-radius: 1rem;
  width: 180px;
  display: flex;
  padding: 0.5rem;
  margin-bottom: 1rem;
}
.switch-button {
  border-radius: 0.5rem;
  width: 50%;
  padding: 0.5rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}
.switch-active {
  background-color: var(--themeColor);
  color: white;
}
.plan-cover {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 1rem;
}
.plan-item {
  padding: 1rem 1.5rem;
  width: 33%;
}
.plan-item:not(:last-child) {
  border-right: 1px solid #ccc;
}
.plan-item h5 {
  font-size: 1rem;
  max-height: 1rem;
}
.plan-fee {
  font-size: 4rem;
  font-weight: 700;
}
.free-text {
  color: #01b884;
}

.plan-button {
  margin-top: 1rem;
  // margin-bottom: 3rem;
  color: white !important;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--themeColor);
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-image 0.5s ease;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: box-shadow 0.3s, transform 0.3s;
}
.plan-button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}
.plan-options {
  // color: var(--themeColor);
  // line-height: 28px;
  font-size: 14px;
}
.plan-option {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin: 10px 0;
}
.plan-option .icon {
  font-weight: 700;
  margin-right: 0.8rem;
}
.free-button {
  background-color: #01b884;
}
.money-button.plan-max {
  background-color: #1f7cff;
}
.contact-button {
  background-color: var(--themeColor);
}
@media screen and (max-width: 768px) {
  .plan-container {
    max-width: 100%;
    padding: 0 15px 20px 15px;
  }
  .plan-cover {
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
  .plan-item {
    width: 35rem !important;
    word-break: normal !important;
    // border: 1px solid #ccc;
    // margin-bottom: 2rem;
    // border-radius: 1rem;
  }
  .plan-option {
    font-size: 1rem;
    line-height: 2rem;
    margin: 2px 0;
  }
  .switch-date {
    width: 100%;
  }
}

.plan-max.plan-item h2,
.plan-max.plan-item .money-text {
  color: #1f7cff;
}
.plan-max .contact-button {
  background-color: #1f7cff;
}
.plan-sales.plan-item h2,
.plan-sales.plan-item .money-text {
  color: #2c2a41;
}
.plan-sales .contact-button {
  background-color: #2c2a41;
}
.money-text {
  color: var(--themeColor);
}
.plan-tag {
  font-size: 1rem;
  color: white;
  display: inline-block;
  line-height: 2rem;
  padding: 0 1rem;
  margin-left: 0.5rem;
  border-radius: 1rem;
}
.plan-max .plan-tag {
  background-color: #1f7cff;
}
.price-text {
  color: #7f76b3;
}
.plans-title {
  /* background-color: var(--themeColor); */
  font-size: 1rem;
  color: white;
  display: inline-block;
  line-height: 2rem;
  padding: 0 1rem;
  margin-left: 0.5rem;
  border-radius: 1rem;
}
.plan-max-button {
  background-color: #1f7cff;
}
.max-color {
  color: #1f7cff;
}
::v-deep .el-dialog__body {
  padding-top: 0;
}
</style>
